import { VENDOR_LISTS } from './vendor-collaboration';

// link generator features
export const ENABLE_SHIFT_TIME_RECEPTION = false;
export const ENABLE_ANNOUNCEMENT_MODE = true;
export const ENABLE_GUEST_LIST = true;
export const ENABLE_PARTNER_MODE = true;

// RSVP form flag feature
export const ENABLE_RSVP = true;
export const RSVP_ENABLE_NAME = true;
export const RSVP_ENABLE_PHONE_NUMBER = true;
export const RSVP_ENABLE_ADDRESS = false;
export const RSVP_ENABLE_EMAIL = false;

// QR Features, set this 2 to true if client order QR.
export const ENABLE_QR_INVITATION = false;
export const ENABLE_QR_NAME_VALIDATION = false;

// giftcard features
export const ENABLE_SEND_GIFT = false;
export const ENABLE_BANK_TRANSFER = true;
export const ENABLE_GIFT_CONFIRMATION = true;

// special features
export const DISABLED_MUSIC_IN_DEVELOPMENT = true;
export const DISABLED_QR_GENERATOR_IN_PRODUCTION = true;
export const ENABLE_DARKMODE_NOTIFICATION = true;
export const MUSLIM_INVITATION = true; // change to `true` if you want to use muslim wording
export const DEFAULT_LANGUAGE = 'en'; // change to `id` if you want to set default to Indonesia
export const ENABLE_MULTI_LANGUAGE = false;
export const ENABLE_COLLABORATION = false; // change to `true` if any collaboration with Invitato partner
export const SELECTED_VENDOR_COLLABORATION = VENDOR_LISTS['aksara']; // change 'aksara' to any of the vendor inside VENDOR_LISTS if want to use a new vendor, just copy and add under the last vendor
export const ENABLE_GUEST_PERSONALIZATION = true; // change to `true` if any different option guest in rsvp
export const ENABLE_VIDEO_PREWEDDING = false; // change to `true` if you want add prewedding video

// global features
export const ENABLE_COVID_PROTOCOL = false;
export const ENABLE_GIFT_CARD = true;
export const ENABLE_LIVE_STREAMING = false;
export const ENABLE_LOVE_STORY = true;
export const ENABLE_WEDDING_FILTER = true;
