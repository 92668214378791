import { DEFAULT_LANGUAGE as LANG } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

export const PASSWORD_LINK_GENERATOR = 'iloveyousomuch';
/**
 * @constants
 * for Brides information
 */
export const IS_BOY_FIRST = false; //change to false for switch broadcast text

export const GIRL_NAME = 'Winda Yunita, S.E.';
export const GIRL_NAME_SHORT = 'Winda';
export const GIRL_FATHER_NAME = `Tumirin (Alm)`;
export const GIRL_MOTHER_NAME = `Mujiati (Almh)`;
export const GIRL_PARENT_NAME =
  LANG === 'en'
    ? `The Daughter of <br /><b>Mr. ${GIRL_FATHER_NAME}</b> <br />and <b>Mrs. ${GIRL_MOTHER_NAME}</b>`
    : `Putri dari <br /><b>Bapak ${GIRL_FATHER_NAME}</b> <br />dan <b>Ibu ${GIRL_MOTHER_NAME}</b>`;

export const BOY_NAME = 'Robbi Salam, S.T.';
export const BOY_NAME_SHORT = 'Robbi';
export const BOY_FATHER_NAME = `Chairul`;
export const BOY_MOTHER_NAME = `Deslina`;
export const BOY_PARENT_NAME =
  LANG === 'en'
    ? `The Son of <br /><b>Mr. ${BOY_FATHER_NAME}</b> <br />and <b>Mrs. ${BOY_MOTHER_NAME}</b>`
    : `Putra dari <br /><b>Bapak ${BOY_FATHER_NAME}</b> <br />dan <b>Ibu ${BOY_MOTHER_NAME}</b>`;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}`
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'salamrobii';
export const IG_GIRL = 'winda.ynt';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/masansgaming/Winda%20Robbi/SEO_HQrTpJOY_.jpg?updatedAt=1702868798341`;
export const SEO_URL = 'https://invitato.net/windarobbiwedding/';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato`;
export const SEO_DESCRIPTION =
  LANG === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1706317200;
export const EPOCH_RESEPSI_START = 1706317200;
export const EPOCH_END_EVENT = 1706335200;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-01-27');
export const DATE_RESEPSI = DATE_AKAD;

export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).locale(LANG).format('DD MMMM YYYY');
export const WEDDING_AKAD = dayjs(DATE_AKAD).locale(LANG).format('dddd, DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '08.00 - 09.00 WIB';
export const WEDDING_LOCATION_AKAD = 'Sedayu City Grand Ballroom';
export const WEDDING_LOCATION_AKAD_ROAD = `Jl. Sunan Sedayu Boulevard Raya, Cakung, Jakarta Timur`;
export const WEDDING_LOCATION_AKAD_ROAD_SIMPLE = ``;

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).locale(LANG).format('dddd');
export const WEDDING_RESEPSI = WEDDING_DATE_DATE;
export const WEDDING_RESEPSI_TIME = '11.00 - 13.00 WIB';
export const WEDDING_LOCATION = 'Sedayu City Grand Ballroom';
export const WEDDING_RESEPSI_CITY = '';
export const WEDDING_LOCATION_ROAD = `Jl. Sunan Sedayu Boulevard Raya, Cakung, Jakarta Timur`;
export const WEDDING_DATE = dayjs(DATE_RESEPSI).locale(LANG).format('DD • MM • YYYY');

/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://windarobbiwedding.com/';
export const BROADCAST_WEDDING_LOCATION = `Sedayu City Grand Ballroom`;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/Yk8XJNpL7xQnbGEw7`;
export const GOOGLE_MAPS_ADDRESS = `Sedayu City Grand Ballroom`;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = ` Barbra Streisand ft Bryan Adams – I Finally Found Someone`;
export const SOUND_URL = 'https://www.youtube.com/watch?v=XBPcBUJb6o0';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://bit.ly/RAIofSENshine';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/LNhRG43cpGE';
export const PREWEDDING_LINK = 'https://bit.ly/RAIofSENshine';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/LNhRG43cpGE';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = `https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!`;
