/**
 * @constant API_HOSTNAME
 * Replace with your Google AppScript Link
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbymBXFX1ND1ee1mnirNwLPxyMKhp5lIhwwqmhiQrAPZqsUbMH44N_ilZlS_qiZFksv3/exec`;

/**
 * @constant RSVP_STATUS
 * DO NOT CHANGES!
 */
export const RSVP_STATUS = {
  attend: 'ATTEND',
  notAttend: 'NOT_ATTEND',
  sent: 'SENT',
  opened: 'OPENED',
  waiting: 'WAITING',
};

/**
 * @constant ACTION_POST_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_POST_TYPE = {
  insertGuest: 'insert_guest',
  insertWish: 'insert_wish',
  insertGiftConfirmation: 'insert_gift_confirmation',
  updateRsvp: 'update_rsvp',
};

/**
 * @constant ACTION_GET_TYPE
 * DO NOT CHANGES!
 */
export const ACTION_GET_TYPE = {
  read: 'read',
  readQrCode: 'read_qrcode',
};

/**
 * @constant API_TABLE_TYPE
 * DO NOT CHANGES!
 */
export const API_TABLE_TYPE = {
  wishes: 'WISHES',
  rsvp: 'RSVP',
  giftConfirmation: 'GIFT_CONFIRMATION',
};
