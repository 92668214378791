import { ENABLE_SHIFT_TIME_RECEPTION } from '@constants/feature-flags';
import useQueryString from './useQueryString';
import { WEDDING_RESEPSI_TIME } from '@constants/index';

export const SHIFT_LIST = [
  {
    value: '1',
    shift: '11.00 - 12.00 WIB',
    name: 'Sesi 1 (11.00 - 12.00 WIB)',
  },
  {
    value: '2',
    shift: '12.00 - 13.00 WIB',
    name: 'Sesi 2 (12.00 - 13.00 WIB)',
  },
];

function generateShift(guestShift) {
  const { getQueryValue } = useQueryString();
  let shift = WEDDING_RESEPSI_TIME;

  if (!ENABLE_SHIFT_TIME_RECEPTION) return WEDDING_RESEPSI_TIME;

  if (guestShift) {
    shift = SHIFT_LIST.find((s) => s.value === guestShift.toString());
  } else {
    shift = SHIFT_LIST.find((s) => s.value === getQueryValue('shift'));
  }

  return shift ? shift.shift : 'Shift not found';
}

export default generateShift;
